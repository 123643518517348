import styled from 'styled-components';

const PageTitle = styled.h2`
  font-size: 32px;
  color: #6296cf;
  font-family: 'Berlin Sans FB', sans-serif;
  font-weight: 400;
  margin: 0;
  margin-bottom: 30px;

  @media (min-width: 720px) {
    font-size: 38px;
  }
`;

export default PageTitle;

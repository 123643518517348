import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import PageTitle from '../components/PageTitle';
import Text from '../components/Text';
import BackButton from '../components/BackButton';

export const ImprintPageTemplate: React.FC<any> = ({ data }) => {
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <PageTitle>{data.title}</PageTitle>
      <Text>{data.text}</Text>
      <div>
        <h3>{data.imagesResponsibility.title}</h3>
        <span>{data.imagesResponsibility.name}</span>
      </div>
      <div>
        <h3>{data.tech.title}</h3>
        <span>{data.tech.name}</span>
      </div>
      <div>
        <h3>{data.imagesTitle}</h3>
        <div>
          {data.images.map(({ name, url }) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '12px',
              }}
            >
              <span>{name}</span>
              <a href={url}>{url}</a>
            </div>
          ))}
        </div>
      </div>
      <BackButton
        to="/"
        style={{ marginTop: '40px' }}
        text="Zurück zur Startseite"
      />
    </div>
  );
};

const ImprintPage: React.FC<{ data: any }> = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ImprintPageTemplate data={post.frontmatter} />
    </Layout>
  );
};

export default ImprintPage;

export const query = graphql`
  query ImprintPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        text
        imagesTitle
        imagesResponsibility {
          name
          title
        }
        tech {
          name
          title
        }
        images {
          name
          url
        }
      }
    }
  }
`;

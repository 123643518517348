import React, { CSSProperties } from 'react';
import { Link } from 'gatsby';
import { FiArrowLeft } from 'react-icons/fi';
import styled from 'styled-components';

interface Props {
  to: string;
  text?: string;
  style?: CSSProperties;
}

const BackButton: React.FC<Props> = ({ to, text = 'Zurück', style = {} }) => {
  return (
    <StyledLink to={to} style={style}>
      <FiArrowLeft />
      <span>{text}</span>
    </StyledLink>
  );
};

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: #6296cf;
  text-decoration: none;
  font-size: 20px;

  span {
    padding-left: 12px;
    font-weight: 600;
  }
`;

export default BackButton;
